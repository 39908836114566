import { Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconTrashOff } from "@tabler/icons-react";
import { DefectAutoComplete } from "components/common/autoComplete/defect/DefectAutoComplete";
import { BaseButton } from "components/common/button/baseButton";
import ChangeNumberButton from "components/common/button/changeNumberButton";
import { BoxNumberInput } from "components/common/input/boxNumberInput";
import { BaseTitle } from "components/common/title/BaseTitle";
import { DefectObject } from "components/common/types";
import { DefectReportBoxProps } from "components/pages/types";
import { WorkSection } from "layouts/pages/work/section";
import { useState } from "react";
import { useTranslation } from "react-i18next";



const ReportBox = Object.assign({}, {
  Title: BaseTitle,
  Button: BaseButton,
  Section: WorkSection,
  AutoComplete: DefectAutoComplete,
  Input: BoxNumberInput,
  ChangeNumberButton: ChangeNumberButton,
});

export const WorkDefectReportBox = (params: DefectReportBoxProps) => {
  const [defectCode, setDefectCode] = useState<string | null>("");
  const { value, onSubmit } = params;

  const form = useForm({
    initialValues: {
      defects: {} as DefectObject,
    },
  });
  const { t } = useTranslation();

  return (
    <ReportBox.Section>
      <ReportBox.Section>
        <Flex w={'100%'} justify={'space-between'}>
          <ReportBox.Title fz={"1.625rem"} c={"#FFFFFF"}>{t('불량 보고')}</ReportBox.Title>
          <ReportBox.Button
            size="md"
            disabled={!defectCode}
            onClick={() => {
              onSubmit && onSubmit({
                ...form.values.defects[defectCode as string],
                defectCode: defectCode as string
              });
              setDefectCode(null);
            }}
            type={"submit"}
            bg={'red.6'}
            rightIcon={<IconTrashOff size={'2rem'} />}
          >{t('불량 보고')}</ReportBox.Button>
        </Flex>
      </ReportBox.Section>
      <Flex gap={10}>
        <ReportBox.AutoComplete
          size="lg"
          radius="xs"
          value={defectCode}
          onChange={(defectCode: string | null) => {
            if (defectCode !== null) {
              form.setValues({
                defects: {
                  ...form.values.defects,
                  [defectCode as string]: {
                    ...form.values.defects?.[defectCode || ""],
                    defectName: value?.[defectCode as string]?.defectName,
                    defectQuantity: '0'
                  }
                }
              })
            }
            setDefectCode(defectCode)
          }}
        />
        <ReportBox.ChangeNumberButton
          btnType="decrease"
          size="lg"
          color="grape.8"
          num={5}
          disabled={!defectCode}
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
        />
        <ReportBox.ChangeNumberButton
          btnType="decrease"
          size="lg"
          color="grape.6"
          num={1}
          disabled={!defectCode}
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
        />
        <ReportBox.Input
          size="lg"
          disabled={!defectCode}
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
          value={Number(form.values.defects?.[defectCode || ""]?.defectQuantity)}
        />
        <ReportBox.ChangeNumberButton
          size="lg"
          color="cyan.6"
          num={1}
          disabled={!defectCode}
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
        />
        <ReportBox.ChangeNumberButton
          size="lg"
          color="indigo.6"
          num={5}
          disabled={!defectCode}
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
        />
      </Flex>
    </ReportBox.Section>
  );
};